import { UserRole } from 'enums/UserRole';
import React from 'react';
import { Navigate } from 'react-router';
import { Paths } from 'routes';
import LocalStorageManager from 'utils/LocalStorageManager';

export default function RedirectRoot(): JSX.Element {
    const user = LocalStorageManager.getUser();
    if (LocalStorageManager.hasRole([UserRole.ADMIN, UserRole.MEMBER, UserRole.JUDGE, UserRole.PRESIDENT])) {
        return <Navigate to={Paths.ADMIN_DASHBOARD} replace />;
    } else if (LocalStorageManager.hasRole([UserRole.USER])) {
        return <Navigate to={Paths.USER_TENDERS_LIST} replace />;
    } else {
        if (user && !user.accountId) {
            return <Navigate to={Paths.ACCOUNT} replace />;
        } else {
            return <Navigate to={Paths.PUBLIC_TENDERS_LIST} replace />;
        }
    }
}
