
// highlight
// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import moment from 'moment';
import 'moment/locale/hu';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
// lightbox
import 'react-image-lightbox/style.css';
import { BrowserRouter } from 'react-router-dom';
// scroll bar
import 'simplebar/src/simplebar.css';
import App from './App';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import './utils/highlight';

moment.locale('hu');
console.log(moment().format('L'));

ReactDOM.render(
    <HelmetProvider>
        <SettingsProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </SettingsProvider>
    </HelmetProvider>,
    document.getElementById('root'),
);
