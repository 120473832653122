/* eslint-disable no-unused-vars */
enum TenderState {
    DRAFT = 'draft',
    PUBLISHED = 'published',
    SUSPENDED = 'suspended',
    INVITATION_CORRECTION = 'invitation_correction',
    SCORE_DRAFT = 'score_draft',
    SCORE_FINALIZED = 'score_finalized',
    VOTE = 'vote',
    VOTE_ACCEPTED = 'vote_accepted',
    CONTRACT = 'contract',
    CONTRACT_CORRECTION = 'contract_correction',
    SETTLEMENT = 'settlement',
    SETTLEMENT_CORRECTION = 'settlement_correction',
    CLOSED = 'closed',
}

export const TenderStateNames = {
    [TenderState.DRAFT]: 'Piszkozat',
    [TenderState.PUBLISHED]: 'Publikálva',
    [TenderState.SUSPENDED]: 'Felfüggesztve',
    [TenderState.INVITATION_CORRECTION]: 'Pályazatbeadás hiánypótlás',
    [TenderState.SCORE_DRAFT]: 'Értékelés',
    [TenderState.SCORE_FINALIZED]: 'Értékelés véglegesítve',
    [TenderState.VOTE]: 'Szavazás',
    [TenderState.VOTE_ACCEPTED]: 'Szavazás elfogadva',
    [TenderState.CONTRACT]: 'Szerződéskötés',
    [TenderState.CONTRACT_CORRECTION]: 'Szerződéskötés hiánypótlás',
    [TenderState.SETTLEMENT]: 'Elszámolás',
    [TenderState.SETTLEMENT_CORRECTION]: 'Elszámolás hiánypótlás',
    [TenderState.CLOSED]: 'Lezárva',
};

export const TenderStateOptions = Object
    .entries(TenderStateNames)
    .map((e) => ({ value: e[0] as TenderState, label: e[1] }));

export default TenderState;
