/* eslint-disable @typescript-eslint/ban-ts-comment */
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import {
    Box, Collapse, List,
    ListItem, ListItemIcon, ListItemText, ListSubheader,
} from '@material-ui/core';
// material
import { alpha, experimentalStyled as styled, useTheme } from '@material-ui/core/styles';
import { Subheader } from 'layouts/dashboard/SidebarConfig';
import React, { useState } from 'react';
import { matchPath, NavLink as RouterLink, useLocation } from 'react-router-dom';
// theme
import typography from '../theme/typography';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
    <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
    ...typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary,
}));

const ListItemStyle = styled(ListItem)(({ theme }) => ({
    ...typography.body2,
    'height': 48,
    'position': 'relative',
    'textTransform': 'capitalize',
    'paddingLeft': theme.spacing(5),
    'paddingRight': theme.spacing(2.5),
    'color': theme.palette.text.secondary,
    '&:before': {
        top: 0,
        right: 0,
        width: 3,
        bottom: 0,
        content: '\'\'',
        display: 'none',
        position: 'absolute',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: theme.palette.primary.main,
    },
}));

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

// ----------------------------------------------------------------------

type NavItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  children?: {
    title: string;
    path: string;
  }[];
};

function NavItem({ item }: { item: NavItemProps }) {
    const theme = useTheme();
    const { pathname } = useLocation();
    const { title, path, icon, info, children } = item;
    const isActiveRoot = path ? !!matchPath({ path, end: true }, pathname) : false;

    const [open, setOpen] = useState(isActiveRoot);

    const handleOpen = () => {
        setOpen(!open);
    };

    const activeRootStyle = {
        'color': 'primary.main',
        'fontWeight': 'fontWeightMedium',
        'bgcolor': alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        '&:before': { display: 'block' },
    };

    const activeSubStyle = {
        color: 'text.primary',
        fontWeight: 'fontWeightMedium',
    };

    if (children) {
        return (
            <>
                <ListItemStyle
                    button
                    disableGutters
                    onClick={handleOpen}
                    sx={{
                        ...(isActiveRoot && activeRootStyle),
                    }}
                >
                    <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                    <ListItemText disableTypography primary={title} sx={{ textTransform: 'initial' }}/>
                    {info && info}
                    <Box
                        component={Icon}
                        icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                        sx={{ width: 16, height: 16, ml: 1 }}
                    />
                </ListItemStyle>

                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {children.map((item) => {
                            const { title, path } = item;
                            const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;

                            return (
                                <ListItemStyle
                                    button
                                    disableGutters
                                    key={title}
                                    // @ts-ignore
                                    component={RouterLink}
                                    to={path}
                                    sx={{
                                        ...(isActiveSub && activeSubStyle),
                                    }}
                                >
                                    <ListItemIconStyle>
                                        <Box
                                            component="span"
                                            sx={{
                                                width: 4,
                                                height: 4,
                                                display: 'flex',
                                                borderRadius: '50%',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                bgcolor: 'text.disabled',
                                                transition: (theme) => theme.transitions.create('transform'),
                                                ...(isActiveSub && {
                                                    transform: 'scale(2)',
                                                    bgcolor: 'primary.main',
                                                }),
                                            }}
                                        />
                                    </ListItemIconStyle>
                                    <ListItemText disableTypography primary={title} />
                                </ListItemStyle>
                            );
                        })}
                    </List>
                </Collapse>
            </>
        );
    }

    return (
        <ListItemStyle
            button
            disableGutters
            // @ts-ignore
            component={RouterLink}
            to={path}
            sx={{
                ...(isActiveRoot && activeRootStyle),
            }}
        >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            <ListItemText disableTypography primary={item.title} sx={{ textTransform: 'initial' }} />
            {info && info}
        </ListItemStyle>
    );
}

type NavSectionProps = {
    navConfig: Subheader[]
}

export default function NavSection({ navConfig }: NavSectionProps): JSX.Element {
    return (
        <Box>
            {navConfig.map((list) => {
                const { subheader, items } = list;
                return (
                    <List key={subheader} disablePadding>
                        <ListSubheaderStyle>{subheader}</ListSubheaderStyle>
                        {items
                            .filter((i) => !i.hidden)
                            .map((item, i) => (
                                <NavItem key={i} item={item} />
                            ))}
                    </List>
                );
            })}
        </Box>
    );
}
