import React from 'react';
import { Link as RouterLink, Outlet } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import { experimentalStyled as styled } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    left: 0,
    lineHeight: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 5, 0),
    },
}));


// ----------------------------------------------------------------------

export default function LogoOnlyLayout(): JSX.Element {
    const theme = useTheme();
    return (
        <>
            <HeaderStyle>
                <RouterLink to="/">
                    {theme.palette.mode === 'light' ? (

                        <img alt='MTPA' src={`/static/icons/MTPA-logo-color.svg`}
                            style={{ maxWidth: '300px', maxHeight: '30px' }}/>

                    ) : (

                        <img alt='MTPA' src={`/static/icons/MTPA-logo-white.svg`}
                            style={{ maxWidth: '300px', maxHeight: '30px' }}/>

                    )}
                </RouterLink>
            </HeaderStyle>
            <Outlet />
        </>
    );
}
