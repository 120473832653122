/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Drawer, Link, ListItemIcon, Typography } from '@material-ui/core';
import { alpha, experimentalStyled as styled, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Paths } from 'routes';
import LocalStorageManager from 'utils/LocalStorageManager';
import { MHidden } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
import getSidebarConfig from './SidebarConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
    },
}));

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.grey[500_12],
}));

const DocStyle = styled('div')(({ theme }) => ({
    padding: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadiusMd,
    backgroundColor:
    theme.palette.mode === 'light' ?
        alpha(theme.palette.primary.main, 0.08) :
        theme.palette.primary.lighter,
}));

const ListItemIconStyle = styled(ListItemIcon)({
    width: 36,
    height: 36,
    display: 'flex',
    marginBottom: 2,
    alignItems: 'center',
    justifyContent: 'center',
});

// ----------------------------------------------------------------------

type AppSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function AppSidebar({ isOpenSidebar, onCloseSidebar }: AppSidebarProps): JSX.Element {
    const theme = useTheme();

    const user = useMemo(() => LocalStorageManager.getUser(), []);

    useEffect(() => {
        if (isOpenSidebar) onCloseSidebar();
    }, []);

    const renderContent = (
        <Scrollbar
            sx={{
                'height': '100%',
                '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' },
            }}
        >

            <Box component={RouterLink} to="/" sx={{ display: 'inline-flex', ml: 2.5, py: 3 }}>
                {theme.palette.mode === 'light' ? (

                    <img alt='MTPA' src={`/static/icons/MTPA-logo-color.svg`}
                        style={{ maxWidth: '300px', maxHeight: '30px' }}/>

                ) : (

                    <img alt='MTPA' src={`/static/icons/MTPA-logo-white.svg`}
                        style={{ maxWidth: '300px', maxHeight: '30px' }}/>

                )}
            </Box>

            <Box sx={{ mb: 2, mx: 2.5 }}>
                {LocalStorageManager.getUser() ?
                    <Link underline="none" component={RouterLink} to={Paths.USER_PROFILE}>
                        <AccountStyle>
                            <MyAvatar />
                            <Box sx={{ ml: 2 }}>
                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                    {user?.lastName} {user?.firstName}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {user?.role}
                                </Typography>
                            </Box>
                        </AccountStyle>
                    </Link> :
                    <Button fullWidth href={Paths.LOGIN} variant="contained" sx={{ mt: 3 }}>
                        Bejelentkezés
                    </Button>
                }
            </Box>

            <NavSection navConfig={getSidebarConfig()} />

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
                <DocStyle>
                    <ListItemIconStyle>
                        <img alt='Contact' src={`/static/icons/navbar/ic_notification_chat.svg`}/>
                    </ListItemIconStyle>
                    <Typography gutterBottom variant="subtitle1" sx={{ color: 'grey.800' }}>
                        Kérdése lenne?
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2, color: 'grey.600' }}>
                        Örömmel segítünk, kérjük vegye fel velünk a kapcsolatot!
                    </Typography>
                    <Button fullWidth href='https://mtpa.hu/kapcsolat' target="_blank" variant="contained">
                        Kapcsolat
                    </Button>
                </DocStyle>
            </Box>
        </Scrollbar>
    );

    return (
        <RootStyle>
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
}
