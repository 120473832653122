import { UserRole } from 'enums/UserRole';
import User from 'models/User';
import React from 'react';
import { Paths } from 'routes';
import LocalStorageManager from 'utils/LocalStorageManager';
import SvgIconStyle from '../../components/SvgIconStyle';

const getIcon = (name: string) => (
    <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    calendar: getIcon('ic_calendar'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    kanban: getIcon('ic_kanban'),
    components: getIcon('ic_components'),
    page: getIcon('ic_page'),
    upload: getIcon('ic_upload'),
    edit: getIcon('ic_edit'),
};

export type Subheader = {
    subheader: string;
    items: SubheaderItem[];
    icon?: JSX.Element;
};

type SubheaderItem = {
    title: string;
    path: string;
    icon: JSX.Element;
    hidden?: boolean;
};

const getSidebarConfig = (): Subheader[] => {
    const user = LocalStorageManager.getUser();

    if (!user || typeof user?.role === 'undefined') return [];

    // General sidebar
    const general: Subheader = {
        subheader: '',
        items: [
            {
                title: 'Beérkező levelek',
                path: Paths.MESSAGES,
                icon: ICONS.mail,
            },
            {
                title: 'Pályázati kiírások',
                path: User.hasRole(user, [UserRole.ADMIN]) ?
                    Paths.ADMIN_TENDERS_LIST :
                    Paths.USER_TENDERS_LIST,
                icon: ICONS.components,
            },
            {
                title: 'Projektek',
                path: User.hasRole(user, [UserRole.ADMIN]) ?
                    Paths.ADMIN_PROJECTS_LIST :
                    Paths.USER_PROJECTS_LIST,
                icon: ICONS.page,
            },
            // {
            //     title: 'Fájlok',
            //     path: '',
            //     icon: ICONS.upload,
            // },
            {
                title: 'Rendezvények',
                path: Paths.USER_PROGRAMS,
                icon: ICONS.calendar,
                hidden: !User.hasRole(user, [UserRole.USER])
            }
        ],
    };

    // Admin sidebar
    const admin: Subheader = {
        subheader: 'adminisztráció',
        items: [
            {
                title: 'Áttekintő',
                path: Paths.ADMIN_DASHBOARD,
                icon: ICONS.dashboard,
            }
        ]
    };

    if (User.hasRole(user, [UserRole.ADMIN, UserRole.PRESIDENT])) {
        admin.items = admin.items.concat([
            {
                title: 'Felhasználók',
                path: Paths.ADMIN_ACCOUNTS_LIST,
                icon: ICONS.user,
            }]
        );
    }

    if (User.hasRole(user, [UserRole.ADMIN, UserRole.PRESIDENT, UserRole.MEMBER])) {
        admin.items = admin.items.concat([
            {
                title: 'Pályázatok kezelése',
                path: Paths.ADMIN_TENDERS_TABLE_LIST,
                icon: ICONS.edit,
            }
        ]);
    }

    return User.hasRole(user, [UserRole.ADMIN, UserRole.MEMBER, UserRole.JUDGE, UserRole.PRESIDENT]) ?
        [general, admin] :
        [general];
};

export default getSidebarConfig;
