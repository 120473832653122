import React from 'react';
import NotistackProvider from './components/NotistackProvider';
import ScrollToTop from './components/ScrollToTop';
import Settings from './components/settings';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import Router from './routes';
import ThemeConfig from './theme';

export default function App(): JSX.Element {
    return (
        <ThemeConfig>
            <ThemePrimaryColor>
                <NotistackProvider>
                    <Settings />
                    <ScrollToTop />
                    <Router />
                </NotistackProvider>
            </ThemePrimaryColor>
        </ThemeConfig>
    );
}
