const env = process.env.NODE_ENV || 'production';

interface ConfigInterface {
    env: string;
    api: {
        protocol: string;
        baseUrl: string;
        port: number;
    }
    timeout: number;
    loginUrl: string;
    refreshTokenExpiry: number;
    paginationOptionsCount: number;
    uploadLimit: number;
    recaptchaSiteKey: string;
    regex: {
        lowerCase: RegExp,
        upperCase: RegExp,
        special: RegExp,
        number: RegExp,
    }
}

const baseConfig:ConfigInterface = {
    env: 'development',
    timeout: 4000,
    loginUrl: '/login',
    refreshTokenExpiry: 86400,
    paginationOptionsCount: 5,
    uploadLimit: 5000000,
    api: {
        protocol: 'http',
        baseUrl: 'localhost',
        port: 6060,
    },
    recaptchaSiteKey: '6Ldo09IbAAAAAGXM-qBG-9av3A17bmSesGkvJbWb',
    regex: {
        lowerCase: /[a-z]/,
        upperCase: /[A-Z]/,
        // eslint-disable-next-line no-useless-escape
        special: /[@$!%*?&\+\-._:/\\=]/,
        number: /\d+/,
    }
};

const prodConfig:ConfigInterface = {
    ...baseConfig,
    api: {
        protocol: 'https',
        baseUrl: 'api.mtpa.hu',
        port: 443,
    },
};

const config:ConfigInterface = env === 'production' ? prodConfig : baseConfig;
export default config;
