import { UserRole } from 'enums/UserRole';
import User from 'models/User';

class LocalStorageManager {
    getUser() {
        const raw = localStorage.getItem('user');
        if (raw && raw !== 'undefined') {
            const user = JSON.parse(raw);
            return user as User;
        } else {
            return;
        }
    }

    async setUser(user: User) {
        localStorage.setItem('user', JSON.stringify(user));
    }

    hasRole(roles: UserRole[]) {
        const user = this.getUser();
        if (!user?.role) return false;
        return roles.includes(user.role);
    }
}

export default new LocalStorageManager();
