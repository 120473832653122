import editFill from '@iconify/icons-eva/edit-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Icon } from '@iconify/react';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import Project from 'models/Project';
import React, { useRef, useState } from 'react';
import ChangeProjectPosition from './ChangeProjectPosition';

type ChangeTenderPositionMenuProps = {
  projectId?: string;
  setProjects: (projects: Project[]) => void;
  currentOrder?: number;
};

export default function ChangeTenderPositionMenu({
    projectId,
    setProjects,
    currentOrder
}: ChangeTenderPositionMenuProps): JSX.Element {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isPlacementOpen, setPlacementOpen] = useState(false);

    const handleClickPlacementOpen = () => {
        setPlacementOpen(true);
    };

    const handlePlacementClose = () => {
        setPlacementOpen(false);
    };

    const isDisabled = typeof currentOrder === 'undefined' || typeof projectId === 'undefined';

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Icon icon={moreVerticalFill} width={20} height={20} />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' },
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem
                    onClick={handleClickPlacementOpen}
                    sx={{ color: 'text.secondary' }}
                    disabled={isDisabled}>
                    <ListItemIcon>
                        <Icon icon={editFill} width={24} height={24} />
                    </ListItemIcon>
                    <ListItemText primary="Mozgatás" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
            </Menu>
            <ChangeProjectPosition
                open={isPlacementOpen}
                onClose={handlePlacementClose}
                currentOrder={currentOrder ?? 0}
                projectId={projectId ?? ''}
                setProjects={setProjects}
            />
        </>
    );
}
