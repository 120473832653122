// material
import { CssBaseline } from '@material-ui/core';
import { huHU } from '@material-ui/core/locale';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import { createTheme, ThemeOptions, ThemeProvider } from '@material-ui/core/styles';
import React, { ReactNode, useMemo } from 'react';
// hooks
import useSettings from '../hooks/useSettings';
import breakpoints from './breakpoints';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import palette from './palette';
import shadows, { customShadows } from './shadows';
//
import shape from './shape';
import typography from './typography';

// ----------------------------------------------------------------------

type ThemeConfigProps = {
  children: ReactNode;
};

export default function ThemeConfig({ children }: ThemeConfigProps) {
    const { themeMode, themeDirection } = useSettings();
    const isLight = themeMode === 'light';

    const themeOptions: ThemeOptions = useMemo(
        () => ({
            palette: isLight ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' },
            shape,
            typography,
            breakpoints,
            direction: themeDirection,
            shadows: isLight ? shadows.light : shadows.dark,
            customShadows: isLight ? customShadows.light : customShadows.dark,
        }),
        [isLight, themeDirection],
    );

    const theme = createTheme(themeOptions);
    theme.components = { ...componentsOverride(theme), ...huHU.components };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
