import {
    Card, CardContent, CardHeader, Grid,
    InputAdornment,
    MenuItem,
    Stack,
    TextField, Typography
} from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import ProjectResultState from 'enums/ProjectResultState';
import ProjectState from 'enums/ProjectState';
import TenderState from 'enums/TenderState';
import { UserRole } from 'enums/UserRole';
import { StatusCodes } from 'http-status-codes';
import Project from 'models/Project';
import ProjectResult from 'models/ProjectResult';
import Tender from 'models/Tender';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import ApiClient from 'utils/ApiClient';
import LocalStorageManager from 'utils/LocalStorageManager';
import typography from '../../../../theme/typography';


type JudgementProps = {
    project?: Project,
    tender?: Tender,
};

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
}));


export default function Judgement({ project, tender }: JudgementProps): JSX.Element {
    const [result, setResult] = useState<ProjectResult>(() => {
        const newResult = new ProjectResult();
        newResult.accountId = LocalStorageManager.getUser()?.accountId;
        newResult.projectId = project?.id;
        newResult.state = ProjectResultState.DRAFT;
        return newResult;
    });

    const [isLoading, setLoading] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleSave = useCallback((payload: ProjectResult) => {
        if (!payload || result?.state === ProjectResultState.DONE) return;

        payload.project = project;
        setLoading(true);

        payload.scoreFirst = payload.scoreFirst ?? 1;
        payload.scoreSecond = payload.scoreSecond ?? 1;
        payload.scoreThird = payload.scoreThird ?? 1;
        payload.scoreFourth = payload.scoreFourth ?? 1;
        payload.scoreFifth = payload.scoreFifth ?? 1;

        payload.score = payload.scoreFirst + payload.scoreSecond +
        payload.scoreThird + payload.scoreFourth + payload.scoreFifth;

        const promise = payload?.id ?
            ApiClient.updateProjectResult(payload) :
            ApiClient.postProjectResult(payload);

        promise
            .then((res) => {
                setResult(res);
                enqueueSnackbar('Bírálat mentve!', { variant: 'success' });
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Bírálat mentése sikertelen!', { variant: 'error' });
            })
            .then(() => setLoading(false));
    },
    [project]);

    const handleChange = (prop: keyof ProjectResult) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setResult({ ...result, [prop]: event.target.value as typeof prop });
    };

    const isReadonly = result?.state === ProjectResultState.DONE ||
        tender?.state !== TenderState.SCORE_DRAFT ||
        project?.state !== ProjectState.SUBMITTED ||
        !LocalStorageManager.hasRole([UserRole.JUDGE]);

    useEffect(() => {
        if (!project?.id) return;

        ApiClient.getProjectResult(project.id)
            .then((res) => setResult(res.data))
            .catch((err) => {
                if (err?.response?.status !== StatusCodes.NOT_FOUND) {
                    console.error(err);
                    enqueueSnackbar('Bírálat lekérése sikertelen!', { variant: 'error' });
                }
            });
    }, [enqueueSnackbar, project?.id]);

    const scoreValueArray = [
        result?.scoreFirst,
        result?.scoreSecond,
        result?.scoreThird,
        result?.scoreFourth,
        result?.scoreFifth
    ];

    const descriptionValueArray = [
        result?.descriptionFirst,
        result?.descriptionSecond,
        result?.descriptionThird,
        result?.descriptionFourth,
        result?.descriptionFifth
    ];

    const fieldConfig: {
        title: string,
        scoreField: keyof ProjectResult,
        descriptionField: keyof ProjectResult
    } [] =
        [
            {
                title: 'Szakmai tartalom',
                scoreField: 'scoreFirst',
                descriptionField: 'descriptionFirst',
            },
            {
                title: 'Pénzügyi tartalom',
                scoreField: 'scoreSecond',
                descriptionField: 'descriptionSecond',
            },
            {
                title: 'Indokoltság',
                scoreField: 'scoreThird',
                descriptionField: 'descriptionThird',
            },
            {
                title: 'Turisztikai hatás',
                scoreField: 'scoreFourth',
                descriptionField: 'descriptionFourth',
            },
            {
                title: 'Humán erőforrás',
                scoreField: 'scoreFifth',
                descriptionField: 'descriptionFifth',
            },
        ];

    return (
        <Grid container spacing={3}>
            <>
                <Grid item xs={12} md={8}>
                    <Card sx={{ p: 2, mb: 3 }}>
                        <CardHeader
                            title='Bírálói felület'
                            subheader={isReadonly ? 'A bírálat nem szerkeszthető.' : ''}
                        />
                        <CardContent>
                            { fieldConfig.map((field, i) =>
                                <Stack key={i} sx={{ mb: 2 }}>
                                    <LabelStyle sx={{ mr: 1 }}>{field.title}</LabelStyle>
                                    <Stack direction={{ xs: 'column', sm: 'row' }}
                                        spacing={{ xs: 3, sm: 2 }}>
                                        <TextField
                                            style={{ width: '15%' }}
                                            select
                                            value={scoreValueArray[i] ?? 1}
                                            onChange={handleChange(field.scoreField)}
                                            disabled={isReadonly}>
                                            {/* Creates a range of numbers from 0 to 9 */}
                                            {[...Array(10).keys()].map((val, i) =>
                                                <MenuItem key={i} value={i+1}>
                                                    {i+1}
                                                </MenuItem>
                                            )}
                                        </TextField>
                                        <TextField
                                            fullWidth
                                            label='Megjegyzés'
                                            value={descriptionValueArray[i] ?? ''}
                                            onChange={handleChange(field.descriptionField)}
                                            disabled={isReadonly} />
                                    </Stack>
                                </Stack>
                            )
                            }

                            <Stack spacing={{ xs: 3, sm: 2 }}>
                                <TextField
                                    fullWidth
                                    label='Javasolt összeg'
                                    value={(result?.amount ?? 0).toString()}
                                    type='number'
                                    onChange={handleChange('amount')}
                                    disabled={isReadonly}
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>Forint</InputAdornment>,
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    label='Szöveges értékelés'
                                    value={result?.explanation ?? ''}
                                    onChange={handleChange('explanation')}
                                    disabled={isReadonly}
                                    multiline
                                    maxRows={5}
                                />
                            </Stack>

                        </CardContent>
                    </Card>
                </Grid>

                { !isReadonly &&
                    <Grid item xs={12} md={4}>
                        <Stack spacing={3}>

                            <LoadingButton
                                onClick={() => handleSave(result)}
                                loading={isLoading}
                                fullWidth
                                variant='contained'
                                size='large'>
                                Mentés
                            </LoadingButton>

                            <LoadingButton
                                onClick={() => handleSave({ ...result, state: ProjectResultState.DONE })}
                                loading={isLoading}
                                fullWidth
                                variant='contained'
                                size='large'>
                                Véglegesítés
                            </LoadingButton>

                        </Stack>
                    </Grid>
                }
            </>
        </Grid>
    );
}
