import ProjectState from 'enums/ProjectState';

class ProjectStateMap {
    private transitions = new Map<ProjectState, {transition: ProjectState[]}>([
        [ProjectState.INVITATION_DRAFT, {
            transition: [
                ProjectState.INVITATION_FINALIZED,
                ProjectState.UNSUCCESSFUL,
            ],
        }],
        [ProjectState.INVITATION_FINALIZED, {
            transition: [
                ProjectState.INVITATION_CORRECTION_DRAFT,
                ProjectState.SUBMITTED,
            ],
        }],
        [ProjectState.INVITATION_CORRECTION_DRAFT, {
            transition: [
                ProjectState.INVITATION_CORRECTION_FINALIZED,
                ProjectState.UNSUCCESSFUL,
            ],
        }],
        [ProjectState.INVITATION_CORRECTION_FINALIZED, {
            transition: [
                ProjectState.INVITATION_CORRECTION_DRAFT,
                ProjectState.SUBMITTED,
            ],
        }],
        [ProjectState.SUBMITTED, {
            transition: [
                ProjectState.SUCCESSFUL,
                ProjectState.UNSUCCESSFUL,
            ],
        }],
        [ProjectState.UNSUCCESSFUL, {
            transition: [
                ProjectState.INVITATION_DRAFT,
                ProjectState.CLOSED,
            ],
        }],
        [ProjectState.SUCCESSFUL, {
            transition: [
                ProjectState.CONTRACT_DRAFT,
            ],
        }],
        [ProjectState.CONTRACT_DRAFT, {
            transition: [
                ProjectState.CONTRACT_UNSUCCESSFUL,
                ProjectState.CONTRACT_FINALIZED,
            ],
        }],
        [ProjectState.CONTRACT_FINALIZED, {
            transition: [
                ProjectState.CONTRACT_CORRECTION_DRAFT,
                ProjectState.CONTRACT_SUBMITTED,
            ],
        }],
        [ProjectState.CONTRACT_CORRECTION_DRAFT, {
            transition: [
                ProjectState.CONTRACT_UNSUCCESSFUL,
                ProjectState.CONTRACT_CORRECTION_FINALIZED,
            ],
        }],
        [ProjectState.CONTRACT_CORRECTION_FINALIZED, {
            transition: [
                ProjectState.CONTRACT_CORRECTION_DRAFT,
                ProjectState.CONTRACT_SUBMITTED,
            ],
        }],
        [ProjectState.CONTRACT_SUBMITTED, {
            transition: [
                ProjectState.PAYOUT,

            ],
        }],
        [ProjectState.PAYOUT, {
            transition: [
                ProjectState.PAID,
            ],
        }],
        [ProjectState.PAID, {
            transition: [
                ProjectState.SETTLEMENT_DRAFT,
            ],
        }],
        [ProjectState.SETTLEMENT_DRAFT, {
            transition: [
                ProjectState.SETTLEMENT_FINALIZED,
            ],
        }],
        [ProjectState.SETTLEMENT_FINALIZED, {
            transition: [
                ProjectState.SETTLEMENT_CORRECTION_DRAFT,
                ProjectState.CLOSED,
            ],
        }],
        [ProjectState.SETTLEMENT_CORRECTION_DRAFT, {
            transition: [
                ProjectState.SETTLEMENT_CORRECTION_FINALIZED,
            ],
        }],
        [ProjectState.SETTLEMENT_CORRECTION_FINALIZED, {
            transition: [
                ProjectState.SETTLEMENT_CORRECTION_DRAFT,
                ProjectState.CLOSED,
            ],
        }],
        [ProjectState.CLOSED, {
            transition: [],
        }],
    ]);

    private editableStates = [
        ProjectState.INVITATION_DRAFT,
        ProjectState.INVITATION_CORRECTION_DRAFT,
        ProjectState.CONTRACT_DRAFT,
        ProjectState.CONTRACT_CORRECTION_DRAFT,
        ProjectState.SETTLEMENT_DRAFT,
        ProjectState.SETTLEMENT_CORRECTION_DRAFT,
    ];

    public get(state: ProjectState) {
        return this.transitions.get(state);
    }

    public isTransitionAllowed(from: ProjectState, to: ProjectState): boolean {
        const item = this.transitions.get(from);
        if (!item) return false;

        // It is assumed that a Project can be closed at any time, except when it is already closed
        return from !== ProjectState.CLOSED &&
        ((to === ProjectState.CLOSED)|| item.transition.includes(to));
    }

    public isAllowedToEdit(state: ProjectState): boolean {
        return this.editableStates.includes(state);
    }
}

export default new ProjectStateMap();
