import { UserRole } from 'enums/UserRole';

/* eslint-disable camelcase */
export default class User {
    public id?: string;
    public accountId?: string;
    public created?: string;
    public email?: string;
    public firstName?: string;
    public lastName?: string;
    public modified?: string;
    public role?: UserRole;
    public status?: string;
    public account?: any;
    public userStatus?: string;

    static hasRole(user?: User, roles?: UserRole[]): boolean {
        if (!user || !user?.role || !roles) return false;
        return roles.includes(user.role);
    }
}
