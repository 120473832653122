/* eslint-disable max-len */
import { Grid, MenuItem, Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import ProjectState from 'enums/ProjectState';
import { UserRole } from 'enums/UserRole';
import { useProjectTransition } from 'hooks/useTransition';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ApiClient from 'utils/ApiClient';
import LocalStorageManager from 'utils/LocalStorageManager';
import { ProjectAdminEditorContext } from './ProjectAdminEditorProvider';

export default function ProjectAdminSideMenu(): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState<boolean>(false);

    const {
        project: [project, setProject],
        projectJudges: [projectJudges]
    } = useContext(ProjectAdminEditorContext);

    const [currentOption, transitionOptions] = useProjectTransition(project?.state);

    const [newProjectState, setNewProjectState] = useState<ProjectState>(project?.state ?? ProjectState.INVITATION_DRAFT);

    const handleSave = useCallback(async () => {
        if (!project?.id) return;

        if (projectJudges && projectJudges.length !== 3 && projectJudges.length !== 0) {
            enqueueSnackbar('A bírálók száma kötelezően 3!', { variant: 'error' });
            return;
        }

        setLoading(true);

        // Update Project state, if changed
        if (newProjectState !== project?.state) {
            await ApiClient.changeProjectState(project?.id, newProjectState)
                .then(() => setProject && setProject({ ...project, state: newProjectState }))
                .catch((err) => {
                    console.error(err);
                    enqueueSnackbar('Projekt állapot frissítés sikertelen!', { variant: 'error' });
                });
        }

        // Update assigned Judges, if changed
        if (projectJudges && projectJudges.length == 3) {
            await ApiClient.postJudges({
                projectId: project?.id,
                members: projectJudges.map((u) => u.id),
            })
                .then(() => {
                    enqueueSnackbar('Bírálók mentve!', { variant: 'success' });
                })
                .catch((err) => {
                    console.error(err);
                    enqueueSnackbar('Bírálók mentése sikertelen!', { variant: 'error' });
                });
        }

        setLoading(false);
    },
    [enqueueSnackbar, newProjectState, project, projectJudges, setProject]);

    useEffect(() => setNewProjectState(project?.state ?? ProjectState.INVITATION_DRAFT), [project?.state]);

    if (LocalStorageManager.hasRole([UserRole.ADMIN]) ) {
        return (
            <Grid item xs={12} md={4}>
                <Stack spacing={2}>
                    <TextField
                        select
                        fullWidth
                        label='Státusz'
                        disabled={newProjectState !== project?.state}
                        value={newProjectState !== project?.state ? newProjectState : currentOption.value}
                        onChange={(e) => setNewProjectState(e.target.value as ProjectState)}>

                        {transitionOptions.map((option) =>
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                disabled={currentOption === option}>
                                {option.label}
                            </MenuItem>
                        )}

                    </TextField>
                    <LoadingButton
                        type='submit'
                        fullWidth
                        variant='contained'
                        size='large'
                        loading={isLoading}
                        onClick={handleSave}>
                        Mentés
                    </LoadingButton>
                </Stack>
            </Grid>
        );
    } else return <></>;
}
