import TenderState from 'enums/TenderState';

class TenderStateService {
    private _transitions = new Map<TenderState, {transition: TenderState[]}>([
        [TenderState.DRAFT, {
            transition: [
                TenderState.PUBLISHED,
            ],
        }],
        [TenderState.PUBLISHED, {
            transition: [
                TenderState.SUSPENDED,
                TenderState.INVITATION_CORRECTION,
            ],
        }],
        [TenderState.SUSPENDED, {
            transition: [TenderState.PUBLISHED],
        }],
        [TenderState.INVITATION_CORRECTION, {
            transition: [
                TenderState.PUBLISHED,
                TenderState.SCORE_DRAFT,
            ],
        }],
        [TenderState.SCORE_DRAFT, {
            transition: [TenderState.SCORE_FINALIZED],
        }],
        [TenderState.SCORE_FINALIZED, {
            transition: [TenderState.VOTE],
        }],
        [TenderState.VOTE, {
            transition: [TenderState.VOTE_ACCEPTED],
        }],
        [TenderState.VOTE_ACCEPTED, {
            transition: [TenderState.CONTRACT],
        }],
        [TenderState.CONTRACT, {
            transition: [TenderState.CONTRACT_CORRECTION],
        }],
        [TenderState.CONTRACT_CORRECTION, {
            transition: [
                TenderState.CONTRACT,
                TenderState.SETTLEMENT,
            ],
        }],
        [TenderState.SETTLEMENT, {
            transition: [TenderState.SETTLEMENT_CORRECTION],
        }],
        [TenderState.SETTLEMENT_CORRECTION, {
            transition: [TenderState.SETTLEMENT], // Can progress to Closed by default
        }],
        [TenderState.CLOSED, {
            transition: [],
        }],
    ]);

    private get transitions() {
        return this._transitions;
    }

    private set transitions(value) {
        this._transitions = value;
    }

    public get(state: TenderState): TenderState[] | undefined {
        return this.transitions.get(state)?.transition;
    }

    public isTransitionAllowed(from: TenderState, to: TenderState): boolean {
        const item = this.transitions.get(from);
        if (!item) return false;

        // It is assumed that a Tender can be closed at any time, except when it is already closed
        return from !== TenderState.CLOSED &&
        ((to === TenderState.CLOSED)|| item.transition.includes(to));
    }
}

export default new TenderStateService();
