enum ProjectResultState {
    DRAFT = 'draft',
    DONE = 'done'
}

export const ProjectResultStateNames = {
    [ProjectResultState.DRAFT]: 'Piszkozat',
    [ProjectResultState.DONE]: 'Véglegesítve',
};
export const ProjectResultStateOptions = Object
    .entries(ProjectResultStateNames)
    .map((e) => ({ value: e[0] as ProjectResultState, label: e[1] }));
export default ProjectResultState;
