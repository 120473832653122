/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import personFill from '@iconify/icons-eva/person-fill';
import { Icon } from '@iconify/react';
// material
import { Box, Button, MenuItem, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useMemo, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Paths } from 'routes';
import { PATH_APP } from 'routes/paths';
import LocalStorageManager from 'utils/LocalStorageManager';
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Profil',
        icon: personFill,
        linkTo: PATH_APP.user.profile,
    },
];

export default function AccountPopover(): JSX.Element {
    const navigate = useNavigate();
    const anchorRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);

    const user = useMemo(() => LocalStorageManager.getUser(), []);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');
        enqueueSnackbar('Sikeres kijelentkezés!', { variant: 'success' });
        navigate(Paths.LOGIN);
    };

    return (
        <>
            <MIconButton
                ref={anchorRef}
                onClick={handleOpen}
                color={open ? 'primary' : 'default'}
            >
                <Box component={Icon} icon={personFill} />
            </MIconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" noWrap>
                        {user?.lastName} {user?.firstName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                {MENU_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={handleClose}
                        sx={{ typography: 'body2', py: 1, px: 2.5 }}
                    >
                        <Box
                            component={Icon}
                            icon={option.icon}
                            sx={{
                                mr: 2,
                                width: 24,
                                height: 24,
                            }}
                        />

                        {option.label}
                    </MenuItem>
                ))}

                <Box sx={{ p: 2, pt: 1.5 }}>
                    <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
                        Kijelentkezés
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}
