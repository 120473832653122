import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function ControlLabel(theme: Theme) {
    return {
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    ...theme.typography.body2,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginTop: theme.spacing(1),
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.disabled,
                },
            },
        },
    };
}
