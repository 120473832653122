/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable new-cap */
import LoadingScreen from 'components/LoadingScreen';
import { Judgement } from 'components/_dashboard/projects/rating';
import { NotificationsProvider } from 'contexts/NotificationsContext';
import { UserRole } from 'enums/UserRole';
import AuthGuard from 'guards/AuthGuard';
import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom';
import AppLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import RedirectRoot from './RootRedirect';

// eslint-disable-next-line react/display-name
const Loadable = (Component: any) => (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();
    const isDashboard = pathname.includes('/dashboard');

    return (
        <Suspense
            fallback={
                <LoadingScreen
                    sx={{
                        ...(!isDashboard && {
                            top: 0,
                            left: 0,
                            width: 1,
                            zIndex: 9999,
                            position: 'fixed',
                        }),
                    }}
                />
            }
        >
            <Component {...props} />
        </Suspense>
    );
};

Loadable.displayName = 'Component';

export const Paths = {
    ADMIN_TENDERS_TABLE_LIST: '/admin/tenders',
    ADMIN_TENDERS_LIST: '/admin/tender-listing',
    ADMIN_TENDERS_EDIT: '/admin/tenders/:id/edit',
    ADMIN_TENDERS_GET: '/admin/tenders/:id',
    ADMIN_TENDER_PREVIEW: '/admin/tenders/:id/preview',
    ADMIN_TENDER_PRESIDENT_DECISION: '/admin/tenders/:id/president',
    ADMIN_TENDER_MEMBER_DECISION: '/admin/tenders/:id/members',
    ADMIN_PROJECTS_LIST: '/admin/projects',
    ADMIN_PROJECTS_GET: '/admin/projects/:id',
    ADMIN_PROJECT_JUDGES: '/admin/projects/:id/judges',
    ADMIN_ACCOUNTS_LIST: '/admin/accounts',
    ADMIN_ACCOUNTS_EDIT: '/admin/accounts/:id',
    ADMIN_ACCOUNTS_LIST_NEW: '/admin/accounts-new',
    ADMIN_DASHBOARD: '/admin/dashboard',

    USER_PROFILE: '/user/profile',
    USER_TENDERS_LIST: '/user/tender-listing',
    USER_TENDERS_GET: '/user/tenders/:id',
    USER_PROJECTS_EDIT: '/user/projects/:id/edit',
    USER_PROJECTS_LIST: '/user/projects',
    USER_PROGRAMS: '/user/programs',

    PASSWORD_RESET: '/password/reset',
    PASSWORD_NEW: '/password/new',

    PUBLIC_TENDERS_GET: '/public/tenders/:id',
    PUBLIC_TENDERS_LIST: '/public/tenders',

    STATUS: '/status',
    PROFILE: '/profile',
    ACTIVATE: '/activate',
    ACCOUNT: '/account',
    PDF: '/generate-pdf',
    LOGIN: '/login',
    REGISTER: '/register',
    MESSAGES: '/messages/',

    INTERNAL_SERVER_ERROR: '/500',
    PAGE_NOT_FOUND: '/404',
};

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: (
                <NotificationsProvider>
                    <Outlet />
                </NotificationsProvider>
            ),
            children: [
                { path: '', element: <RedirectRoot /> },
                {
                    path: '/admin',
                    element: (
                        <AuthGuard permissions={[
                            UserRole.ADMIN,
                            UserRole.JUDGE,
                            UserRole.MEMBER,
                            UserRole.PRESIDENT]} >
                            <AppLayout />
                        </AuthGuard>
                    ),
                    children: [
                        { path: '/tenders', element:
                            <AuthGuard permissions={[UserRole.ADMIN, UserRole.PRESIDENT, UserRole.MEMBER]}>
                                <TenderListPage />
                            </AuthGuard> },

                        { path: '/tender-listing', element: <TenderList /> },

                        { path: '/tenders/:id/edit', element:
                            <AuthGuard permissions={[UserRole.ADMIN, UserRole.PRESIDENT]}>
                                <TenderPage />
                            </AuthGuard> },

                        { path: '/tenders/:id', element: <TenderDescription /> },

                        { path: '/tenders/:id/president', element:
                            <AuthGuard permissions={[UserRole.PRESIDENT]}>
                                <PresidentDecisionPage />
                            </AuthGuard> },

                        { path: '/tenders/:id/members', element:
                            <AuthGuard permissions={[UserRole.MEMBER]}>
                                <MemberDecisionPage />
                            </AuthGuard> },

                        { path: '/tenders/:id/preview', element:
                            <AuthGuard permissions={[UserRole.ADMIN, UserRole.PRESIDENT]}>
                                <ProjectSubmitPage preview={true} />
                            </AuthGuard> },

                        { path: '/projects', element: <ProjectTable /> },
                        { path: '/projects/:id', element: <ProjectRating /> },
                        { path: '/projects/:id/judges', element:
                            <AuthGuard permissions={[UserRole.JUDGE]}>
                                <Judgement />
                            </AuthGuard> },

                        { path: '/accounts', element:
                            <AuthGuard permissions={[UserRole.ADMIN, UserRole.PRESIDENT]}>
                                <AccountListPage />
                            </AuthGuard> },

                        { path: '/accounts/:accountId', element:
                            <AuthGuard permissions={[UserRole.ADMIN, UserRole.PRESIDENT]}>
                                <AccountEditPage />
                            </AuthGuard> },

                        { path: '/dashboard', element: <AdminDashboard /> },
                    ],
                },

                {
                    path: '/user',
                    element: (
                        <AuthGuard permissions={Object.values(UserRole)}>
                            <AppLayout />
                        </AuthGuard>
                    ),
                    children: [
                        { path: '/profile', element: <Profile /> },
                        { path: '/tender-listing', element: <TenderList /> },
                        { path: '/tenders/:id', element: <TenderDescription /> },
                        { path: '/projects', element: <ProjectTable /> },
                        { path: '/projects/:id', element: <h1>nincs kész</h1> },
                        { path: '/projects/:id/edit', element: <ProjectSubmitPage preview={false} /> },
                        { path: '/programs', element: <ProgramPage /> },
                    ],
                },

                {
                    path: '/messages',
                    element: (
                        <AuthGuard permissions={Object.values(UserRole)}>
                            <AppLayout />
                        </AuthGuard>
                    ),
                    children: [
                        {
                            path: '/',
                            element: (
                                <Messages>
                                    <MessageList />
                                </Messages>
                            ),
                        },
                        {
                            path: '/:id',
                            element: (
                                <Messages>
                                    <MessageDetails />
                                </Messages>
                            ),
                        },
                        { path: '/create', element: <Messages /> },
                    ],
                },

                {
                    path: '/public',
                    element: <AppLayout />,
                    children: [
                        { path: '/tenders', element: <TenderList /> },
                        { path: '/tenders/:id', element: <TenderDescription /> },
                    ],
                },
            ],
        },
        { path: Paths.LOGIN, element: <Login /> },
        { path: Paths.REGISTER, element: <Register /> },
        { path: Paths.PDF, element: <AuthGuard permissions={[UserRole.USER]}>
            <PdfRequired /></AuthGuard> },
        { path: Paths.REGISTER, element: <RegistrationCheck /> },
        { path: Paths.ACTIVATE, element: <EmailActivation /> },
        { path: Paths.ACCOUNT, element: <AccountCreation /> },
        { path: Paths.PASSWORD_RESET, element: <ResetPassword /> },
        { path: Paths.PASSWORD_NEW, element: <NewPassword /> },
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                { path: Paths.INTERNAL_SERVER_ERROR, element: <Page500 /> },
                { path: Paths.PAGE_NOT_FOUND, element: <NotFound /> },
                { path: '*', element: <Navigate to={Paths.PAGE_NOT_FOUND} replace /> },
            ],
        },
    ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('pages/authentication/Login')));
const Register = Loadable(lazy(() => import('pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('pages/authentication/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('pages/authentication/NewPassword')));
const PdfRequired = Loadable(lazy(() => import('pages/authentication/PdfRequired')));
const AccountCreation = Loadable(lazy(() => import('pages/authentication/AccountCreation')));
const Profile = Loadable(lazy(() => import('pages/app/Profile')));

// Dashboard
const AdminDashboard = Loadable(lazy(() => import('pages/app/AdminDashboard')));
const TenderList = Loadable(lazy(() => import('components/_dashboard/tenders/TenderList')));
const TenderPage = Loadable(lazy(() => import('components/_dashboard/tenders/TenderPage')));
const TenderDescription = Loadable(lazy(() => import('../components/_dashboard/tenders/TenderDescription')));
const ProjectTable = Loadable(lazy(() => import('../pages/app/ProjectTable')));
const ProjectRating = Loadable(lazy(() => import('../pages/app/ProjectRating')));
const PresidentDecisionPage = Loadable(lazy(() => import('../pages/app/decision/PresidentDecisionPage')));
const MemberDecisionPage = Loadable(lazy(() => import('../pages/app/decision/MemberDecisionPage')));

// Admin
const TenderListPage = Loadable(lazy(() => import('pages/app/admin/TenderListPage')));
const AccountListPage = Loadable(lazy(() => import('pages/app/admin/AccountListPage')));
const AccountEditPage = Loadable(lazy(() => import('pages/app/admin/AccountEditPage')));

// User
const Messages = Loadable(lazy(() => import('../pages/app/Messages')));
const RegistrationCheck = Loadable(lazy(() => import('pages/authentication/RegistrationCheck')));
const EmailActivation = Loadable(lazy(() => import('pages/authentication/EmailActivation')));
const ProgramPage = Loadable(lazy(() => import('../pages/app/ProgramPage')));
const ProjectSubmitPage = Loadable(lazy(() => import('components/_dashboard/projects/submit/ProjectSubmitPage')));

// Messages
const MessageDetails = Loadable(lazy(() => import('components/_dashboard/mail/MessageDetails')));
const MessageList = Loadable(lazy(() => import('components/_dashboard/mail/MessageList')));

// Main
const Page500 = Loadable(lazy(() => import('pages/error/Page500')));
const NotFound = Loadable(lazy(() => import('pages/error/Page404')));
