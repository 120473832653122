import React from 'react';
//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

// TODO: Photo URL?

export default function MyAvatar({ ...other }: MAvatarProps): JSX.Element {
    const localUser = JSON.parse(localStorage.getItem('user') || '{}');

    return (
        <MAvatar
            src={localUser.photoURL}
            alt={localUser.firstName}
            color={localUser.photoURL ? 'default' : createAvatar(localUser.firstName).color}
            {...other}
        >
            {createAvatar(localUser.firstName).name}
        </MAvatar>
    );
}
