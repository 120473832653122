import ProjectResultState from 'enums/ProjectResultState';
import Account from './Account';
import Project from './Project';

export default class ProjectResult {
    public id?: string;
    public scoreFirst?: number;
    public scoreSecond?: number;
    public scoreThird?: number;
    public scoreFourth?: number;
    public scoreFifth?: number;
    public score?: number;
    public amount?: number;
    public descriptionFirst?: string;
    public descriptionSecond?: string;
    public descriptionThird?: string;
    public descriptionFourth?: string;
    public descriptionFifth?: string;
    public explanation?: string;
    public state?: ProjectResultState;
    public accountId?: string;
    public account?: Account;
    public projectId?: string;
    public project?: Project;
}
