/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import config from 'config';
import AttachmentType from 'enums/AttachmentType';
import GrantType from 'enums/GrantType';
import ProjectState from 'enums/ProjectState';
import TenderState from 'enums/TenderState';
import StatusCodes from 'http-status-codes';
import Account from 'models/Account';
import Attachment from 'models/Attachment';
import Comment from 'models/Comment';
import DashboardData from 'models/DashboardData';
import Field from 'models/Field';
import Notification from 'models/Notification';
import Program from 'models/Program';
import Project from 'models/Project';
import ProjectMember from 'models/ProjectMember';
import ProjectResult from 'models/ProjectResult';
import ProjectVote from 'models/ProjectVote';
import Section from 'models/Section';
import Tender from 'models/Tender';
import User from 'models/User';
import { Paths } from 'routes';
import TokenHelper, { Token } from 'utils/TokenHelper';

export const ApiURL =
    config.api.port != 80 && config.api.port != 443 ?
        `${config.api.protocol}://${config.api.baseUrl}:${config.api.port}` :
        `${config.api.protocol}://${config.api.baseUrl}`;

type ListResponse<T> = {
    total: number;
    data: T
};

// TODO: implement handling of pagination and count headers
class ApiClient {
    protected readonly client: AxiosInstance;

    constructor() {
        this.client = axios.create({ baseURL: ApiURL });
        this.client.interceptors.request.use(this.handleAuth);
        this.client.interceptors.response.use(
            (response) => response,
            this.handleUnauthorized);
    }

    private handleAuth = async (config: AxiosRequestConfig) => {
        // If Access Token is valid, set Auth headers
        if (TokenHelper.isValid(Token.ACCESS)) {
            config.headers = this.getHeaders(TokenHelper.getToken(Token.ACCESS));
            return config;
        } else {
            // If Refresh Token is valid, request new tokens
            if (TokenHelper.isValid(Token.REFRESH)) {
                console.log('Invalid access token, requesting new tokens!');
                await this.exchangeTokens();
                return config;
            // If both tokens fail, redirect to Login
            } else {
                console.error('Invalid tokens, canceling AJAX request.');
                window.location.replace(Paths.LOGIN);
                return config;
            }
        }
    };

    private handleUnauthorized = (error: any) => {
        if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
            TokenHelper.removeToken(Token.ACCESS);
            TokenHelper.removeToken(Token.REFRESH);
            localStorage.removeItem('user');
            window.location.replace(Paths.LOGIN);
            throw new axios.Cancel();
        } else {
            return error;
        }
    };

    private getListResponse<T>(response: AxiosResponse<T>):ListResponse<T> {
        const header = response.headers['x-total-count'];
        const total = header ? parseInt(header) : -1;

        const result:ListResponse<T> = {
            data: response.data,
            total
        };

        return result;
    }

    private exchangeTokens = async () => {
        const payload = {
            grant_type: GrantType.PASSWORD,
            refresh_token: TokenHelper.getToken(Token.REFRESH)
        };

        await axios.post(`${ApiURL}/auth/token`, payload)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
                console.error('Token exchange failed, canceling AJAX request, redirecting to Login!');
                window.location.replace(Paths.LOGIN);
                throw new axios.Cancel();
            });
    }

    private getHeaders = (token?: string) => ({
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    })

    // Users

    async getCurrentUser(): Promise<User> {
        const response: AxiosResponse<User> = await this.client.get<User>('/me');
        return response.data;
    }

    async listUsers(): Promise<User[]> {
        const response = await this.client.get<User[]>('/users');
        return response.data;
    }

    async getUser(userId: string): Promise<User> {
        const response: AxiosResponse<User> = await this.client.get<User>(`/users/${userId}`);
        return response.data;
    }

    async postUser(data: any): Promise<User> {
        const response: AxiosResponse<User> = await this.client.post('/users/', data);
        return response.data;
    }

    async updateUser(userId: string, data: any): Promise<User> {
        const response: AxiosResponse<User> = await this.client.put(`/users/${userId}`, data);
        return response.data;
    }

    // Accounts

    async getAccount(accountId: string): Promise<Account> {
        const response: AxiosResponse<Account> = await this.client.get(`/accounts/${accountId}`);
        return response.data;
    }

    async postAccount(data: Account): Promise<Account> {
        const response: AxiosResponse<Account> = await this.client.post('/accounts', data);
        console.log('Status code: ', typeof(response));
        if (response instanceof Error) {
            throw new Error('Bad request');
        } else {
            return response.data;
        }
    }

    async updateAccount(accountId: string, data: Account): Promise<Account> {
        const response: AxiosResponse<Account> = await this.client.put(`/accounts/${accountId}`, data);
        if (response instanceof Error) {
            return Promise.reject(new Error()); // TODO: handle error responses
        }
        return response.data;
    }

    async listAccounts(filter?: {
        ids?: string,
        search?: string
    }, page?: number, size?: number):Promise<ListResponse<Account[]>> {
        const params: {[key:string]: string|undefined} = { ...filter };
        if (page !== undefined) {
            params.page = page.toString();
        }
        if (size !== undefined) {
            params.size = size.toString();
        }
        const url = this.withQuery('/accounts', { ...params });
        const response = await this.client.get<Account[]>(url);
        console.log(response);

        return this.getListResponse<Account[]>(response);
    }

    async activateAccountWithRegDoc(payload: FormData, accountId?: string) {
        const response = await this.client.post(
            `/accounts/${accountId}/upload-signed-reg-doc`,
            payload);
        return response.data;
    }

    async deleteAccount(accountId: string): Promise<void> {
        const response: AxiosResponse<Account> = await this.client.delete(`/accounts/${accountId}`);
        if (response instanceof Error) {
            throw new Error('Bad request');
        } else {
            return Promise.resolve();
        }
    }

    // Programs

    async postProgram(data: Program): Promise<Program> {
        const response: AxiosResponse<Program> = await this.client.post<Program>('/programs', data);
        return response.data;
    }

    async updateProgram(programId: string, data: Program): Promise<Program> {
        const response: AxiosResponse<Program> = await this.client.put<Program>(`/programs/${programId}`, data);
        return response.data;
    }

    async deleteProgram(programId: string): Promise<Program> {
        const response: AxiosResponse<Program> = await this.client.delete<Program>(`/programs/${programId}`);
        return response.data;
    }

    async listPrograms(): Promise<Program[]> {
        const response: AxiosResponse<Program[]> = await this.client.get<Program[]>('/programs');
        return response.data;
    }

    // Tenders

    async listTenders(): Promise<any[]> {
        const response: AxiosResponse<any[]> = await this.client.get<any[]>('/tenders');
        return response.data;
    }

    async getTender(tenderId?: string): Promise<Tender> {
        const response: AxiosResponse<Tender> = await this.client.get<Tender>(`/tenders/${tenderId}`);
        return response.data;
    }

    async postTender(tender: Tender): Promise<Tender> {
        const response: AxiosResponse<Tender> = await this.client.post<Tender>('/tenders', tender);
        return response.data;
    }

    async updateTender(tender: Tender): Promise<Tender> {
        const response = await this.client.put<Tender>(`/tenders/${tender.id}`, tender);
        return response.data;
    }

    async deleteTender(tenderId: any): Promise<Tender> {
        const response: AxiosResponse<Tender> = await this.client.delete<Tender>(`/tenders/${tenderId}`);
        return response.data;
    }

    async changeTenderState(tenderId: any, state: TenderState): Promise<Tender> {
        const response: AxiosResponse<Tender> = await this.client.put<Tender>(
            `/tenders/${tenderId}/change-state`,
            { state }
        );
        return response.data;
    }

    // Sections

    async deleteSection(tenderId: string, sectionId: string): Promise<Section> {
        const response: AxiosResponse<Section> = await this.client.get<Section>(
            `/tenders/${tenderId}/sections/${sectionId}`
        );
        return response.data;
    }

    async listSections(tenderId: any): Promise<Section[]> {
        const response: AxiosResponse<Section[]> = await this.client.get<Section[]>(`/tenders/${tenderId}/sections`);
        return response.data;
    }

    async postSection(tenderId: any, data: Section): Promise<Section> {
        const response: AxiosResponse<Section> = await this.client.post<Section>(`/tenders/${tenderId}/sections`, data);
        return response.data;
    }

    async updateSection(tenderId: any, sectionId: string, data: Section): Promise<Section> {
        const response: AxiosResponse<Section> = await this.client.put<Section>(
            `/tenders/${tenderId}/sections/${sectionId}`,
            data
        );
        return response.data;
    }

    // Fields

    async postField(sectionId: any, data: any): Promise<Field> {
        const response: AxiosResponse<Field> = await this.client.get<Field>(`/sections/${sectionId}/fields`, data);
        return response.data;
    }

    async listFields(sectionId: any): Promise<Field[]> {
        const response: AxiosResponse<Field[]> = await this.client.get<Field[]>(`/sections/${sectionId}/fields`);
        return response.data;
    }

    async updateField(sectionId: any, fieldId: any, data: any): Promise<Field> {
        const response: AxiosResponse<Field> = await this.client.put<Field>(
            `/sections/${sectionId}/fields/${fieldId}`,
            data
        );
        return response.data;
    }

    async deleteField(sectionId: any, fieldId: string): Promise<Field> {
        const response: AxiosResponse<Field> = await this.client.put<Field>(
            `/tenders/${sectionId}/sections/${fieldId}`
        );
        return response.data;
    }
    // Projects

    async listProjects(filter?: {
        accountId?: string,
        tenderId?: string,
        state?: ProjectState,
    }) {
        const url = this.withQuery('/projects', filter);
        return (await this.client.get<Project[]>(url))?.data;
    }

    async postProject(data: Project): Promise<Project> {
        const response: AxiosResponse<Project> = await this.client.post<Project>('/projects', data);
        return response.data;
    }

    async updateProject(project: Project) {
        const response = await this.client.put<Project>(`/projects/${project.id}`, project);
        return response.data;
    }

    async updateProjectsBulk(projects: Project[]) {
        const response = await this.client.put<Project[]>('/projects/bulk', projects);
        return response.data;
    }

    async changeProjectState(projectId: string, newState: ProjectState) {
        const response = await this.client.post<Project>(
            `/projects/${projectId}/change-state`,
            { state: newState });
        return response.data;
    }

    async reorderProject(projectId: string, resultOrder: number, resultOrderComment: string) {
        const response = await this.client.put<Project[]>(
            `/projects/${projectId}/reorder`,
            { resultOrder, resultOrderComment }
        );
        return response.data;
    }

    async getProject(projectId: string): Promise<Project> {
        const response: AxiosResponse<Project> = await this.client.get<Project>(`/projects/${projectId}`);
        return response.data;
    }

    async deleteProject(projectId: string) {
        const response = await this.client.delete(`/projects/${projectId}`);
        return response.data;
    }

    async listProjectComments(projectId: string): Promise<Comment[]> {
        return (await this.client.get<Comment[]>(`/projects/${projectId}/comments`)).data;
    }

    async postCommentForProject(projectId: string, payload: Comment): Promise<Comment> {
        return (await this.client.post<Comment>(`/projects/${projectId}/comments`, payload)).data;
    }

    async bulkUpdateProjectState(projectIds: string[], newState: ProjectState, newDeadline?: Date): Promise<Project[]> {
        const payload = {
            projectIds,
            newState,
            newDeadline
        };
        return (await this.client.put<Project[]>('/projects', payload)).data;
    }

    async bulkUpdateProjectJudges(projectIds: string[], judgeIds: string[]): Promise<{ message: string }> {
        const payload = {
            projectIds,
            judgeIds,
        };
        return (await this.client.post('/projects/judges', payload)).data;
    }

    // Notifications
    async listNotifications(accountId: string): Promise<Notification[]> {
        return (await this.client.get<Notification[]>('/notifications/account/' + accountId)).data;
    }

    async getNotification(id: string): Promise<Notification> {
        return (await this.client.get<Notification>('/notifications/' + id)).data;
    }

    async updateNotificationOpened(id: string): Promise<Notification> {
        return (await this.client.put<Notification>('/notifications/' + id + '/opened')).data;
    }

    async bulkOpenNotifications(notifications: Notification[]): Promise<Notification[]> {
        return (await this.client.put<Notification[]>('/notifications/open', notifications)).data;
    }

    async updateNotifications(notifications: Notification[]): Promise<Notification[]> {
        return (
            await this.client.put<Notification[]>('/notifications', {
                notifications: notifications,
            })
        ).data;
    }

    async postNotification(notification: any): Promise<Notification> {
        return (await this.client.post<Notification>('/notifications', notification)).data;
    }

    async postNotificationForm(formData: any): Promise<Notification> {
        return (await this.client.post<Notification>('/notifications/form', formData)).data;
    }

    // Attachments
    async listAttachments(filter?: {
        accountId?: string,
        tenderId?: string,
        projectId?: string,
        type?: AttachmentType
    }) {
        const url = this.withQuery('/attachments', filter);
        return (await this.client.get<Attachment[]>(url)).data;
    }

    async postAttachment(data: FormData) {
        return (await this.client.post<Attachment>('/attachments', data)).data;
    }

    async putAttachment(attachment: Attachment) {
        return (await this.client.put<Attachment>(`/attachments/${attachment.id}`, attachment)).data;
    }

    async getAttachment(id: string): Promise<Attachment> {
        return (await this.client.get<Attachment>('/attachments/' + id)).data;
    }

    async getAttachmentByFieldValueId(id: string): Promise<Attachment[]> {
        return (await this.client.get<Attachment[]>(`/attachments/field-value/${id}`)).data;
    }

    async getAttachmentDownloadUrl(attachmentId: string): Promise<string> {
        return (await this.client.get<{ url: string }>(`/attachments/${attachmentId}/download-url`)).data.url;
    }

    async deleteAttachment(attachmentId?: string) {
        return (await this.client.delete(`/attachments/${attachmentId}`)).data;
    }

    async deleteAllAttachments(filter?: {
        accountId?: string,
        tenderId?: string,
        projectId?: string,
        type?: AttachmentType
    }) {
        const url = this.withQuery('/attachments', filter);
        return (await this.client.delete(url)).data;
    }

    // Dashboard
    async listDashboardData(): Promise<DashboardData> {
        const response = await this.client.get<DashboardData>('/dashboard');
        return response.data;
    }

    // Judges

    async listJudges():Promise<User[]> {
        const response: AxiosResponse<User[]> = await this.client.get<User[]>('/users/judges/list');
        return response.data;
    }

    async listJudgesByProjectId(projectId: string):Promise<ProjectMember[]> {
        const response: AxiosResponse<ProjectMember[]> = await this.client
            .get<ProjectMember[]>(`/projectmembers/project/${projectId}`);
        return response.data;
    }

    async postJudges(formData: any): Promise<any> {
        const response: AxiosResponse<any> = await this.client.post<any>('/projectmembers/create-bulk', formData);
        return response.data;
    }

    async postProjectResult(result: ProjectResult) {
        const response = await this.client.post<ProjectResult>('/projectresult', result);
        return response.data;
    }

    async updateProjectResult(result: ProjectResult) {
        const response = await this.client.put<ProjectResult>(`/projectresult/${result.id}`, result);
        return response.data;
    }


    /**
     *
     * NOT a RESTful endpoint! Finds entity based on projectId and user!
     * Also returns the AxiosResponse, not the inner data object!
     * @param {string} projectId
     * @return {AxiosResponse<ProjectResult>} AxiosResponse
     */
    async getProjectResult(projectId: string) {
        const response = await this.client.get<ProjectResult>(`/projectresult/${projectId}`);
        return response;
    }

    // Comments

    async postComment(payload: Partial<Comment>, fieldValueId?: string, projectId?: string) {
        let query = '?';
        if (fieldValueId) query = query + 'field_value_id=' + fieldValueId + '&';
        if (projectId) query = query + 'project_id=' + projectId + '&';
        if (query[query.length - 1] === '&') query = query.slice(0, -1);

        return (await this.client.post<Comment>('/comments' + query, payload)).data;
    }

    // ProjectVotes

    async getProjectVotes(tenderId: string) {
        const response = await this.client.get<ProjectVote[]>(`/projectvote?tenderId=${tenderId}`);
        return response.data;
    }

    async postProjectVotesBulk(votes: ProjectVote[]) {
        const response = await this.client.post<ProjectVote[]>('/projectvote/bulk', votes);
        return response.data;
    }

    private withQuery = (path: string, filter?: {[key: string]: string | undefined}) => {
        if (!filter) return path;

        // Generates queryString from an object
        const queryString = new URLSearchParams();
        Object.entries(filter ?? {})
            .filter((e) => typeof e[1] !== 'undefined')
            .map((e) => [e[0].split(/(?=[A-Z])/).join('_').toLowerCase(), e[1]])
            .forEach((e) =>
                e[0] && e[1] && queryString.append(e[0], e[1]));

        return path + '?' + queryString.toString();
    }
}

export default new ApiClient();
