/* eslint-disable no-unused-vars */

export enum ProjectState {
    INVITATION_DRAFT = 'invitation_draft',
    INVITATION_FINALIZED = 'invitation_finalized',
    INVITATION_CORRECTION_DRAFT = 'invitation_correction_draft',
    INVITATION_CORRECTION_FINALIZED = 'invitation_correction_finalized',
    SUBMITTED = 'submitted',
    UNDER_VOTE = 'under_vote',
    UNSUCCESSFUL = 'unsuccessful',
    SUCCESSFUL = 'successful',
    CONTRACT_DRAFT = 'contract_draft',
    CONTRACT_FINALIZED = 'contract_finalized',
    CONTRACT_CORRECTION_DRAFT = 'contract_correction_draft',
    CONTRACT_CORRECTION_FINALIZED = 'contract_correction_finalized',
    CONTRACT_SUBMITTED = 'contract_submitted',
    CONTRACT_UNSUCCESSFUL = 'contract_unsuccessful',
    PAYOUT = 'payout',
    PAID = 'paid',
    SETTLEMENT_DRAFT = 'settlement_draft',
    SETTLEMENT_FINALIZED = 'settlement_finalized',
    SETTLEMENT_CORRECTION_DRAFT = 'settlement_correction_draft',
    SETTLEMENT_CORRECTION_FINALIZED = 'settlement_correction_finalized',
    SETTLEMENT_UNSUCCESSFUL = 'settlement_unsuccessful',
    CLOSED = 'closed'
}

export const ProjectStateNames = {
    [ProjectState.INVITATION_DRAFT]: 'Piszkozat',
    [ProjectState.INVITATION_FINALIZED]: 'Véglegesítve',
    [ProjectState.INVITATION_CORRECTION_DRAFT]: 'Hiánypótlásra küldve',
    [ProjectState.INVITATION_CORRECTION_FINALIZED]: 'Hiánypótlás véglegesítve',
    [ProjectState.SUBMITTED]: 'Beadva',
    [ProjectState.UNDER_VOTE]: 'Szavazásra terjesztve',
    [ProjectState.UNSUCCESSFUL]: 'Sikertelen',
    [ProjectState.SUCCESSFUL]: 'Sikeres',
    [ProjectState.CONTRACT_DRAFT]: 'Szerződés piszkozat',
    [ProjectState.CONTRACT_FINALIZED]: 'Szerződés véglegesítve',
    [ProjectState.CONTRACT_CORRECTION_DRAFT]: 'Szerződés hiánypótlás',
    [ProjectState.CONTRACT_CORRECTION_FINALIZED]: 'Szerződés hiánypótlás véglegesítve',
    [ProjectState.CONTRACT_SUBMITTED]: 'Szerződés beadva',
    [ProjectState.CONTRACT_UNSUCCESSFUL]: 'Szerződés sikertelen',
    [ProjectState.PAYOUT]: 'Kifizetés',
    [ProjectState.PAID]: 'Kifizetve',
    [ProjectState.SETTLEMENT_DRAFT]: 'Elszámolás piszkozat',
    [ProjectState.SETTLEMENT_FINALIZED]: 'Elszámolás véglegesítve',
    [ProjectState.SETTLEMENT_CORRECTION_DRAFT]: 'Elszámolás hiánypótlás',
    [ProjectState.SETTLEMENT_CORRECTION_FINALIZED]: 'Elszámolás hiánypótlás véglegesítve',
    [ProjectState.SETTLEMENT_UNSUCCESSFUL]: 'Elszámolás sikertelen',
    [ProjectState.CLOSED]: 'Lezárva'
};

export const ProjectStateOptions = Object
    .entries(ProjectStateNames)
    .map((e) => ({ value: e[0] as ProjectState, label: e[1] }));

export default ProjectState;
