/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import Project from 'models/Project';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import ApiClient from 'utils/ApiClient';

type ChangeTenderPositionProps = {
  open: boolean;
  onClose: VoidFunction;
  currentOrder: number;
  projectId: string;
  setProjects: (projects: Project[]) => void;
};

export default function ChangeTenderPosition({
    open,
    onClose,
    currentOrder,
    projectId,
    setProjects,
}: ChangeTenderPositionProps): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();

    const [newOrder, setNewOrder] = useState<number>(currentOrder);
    const [description, setDescription] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);

    const handleSave = useCallback(async () => {
        setLoading(true);
        await ApiClient.reorderProject(projectId, newOrder, description)
            .then((res) => setProjects(res))
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Projekt mozgatása sikertelen!', { variant: 'error' });
            })
            .then(() => {
                setLoading(false);
                onClose();
            });
    },
    [description, enqueueSnackbar, newOrder, onClose, projectId, setProjects]);

    return (
        <Dialog fullWidth maxWidth='sm' open={open} onClose={onClose}>
            <DialogTitle>Projekt áthelyezése</DialogTitle>
            <DialogContent>
                <Grid container spacing={3} direction='column'>

                    <Grid item>
                        <TextField
                            fullWidth
                            label='Új helyezés'
                            value={(newOrder ?? 0).toString()}
                            onChange={(e) => setNewOrder(parseInt(e.target.value, 10))}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth
                            label='Indoklás'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Grid>

                </Grid>
            </DialogContent>

            <Divider />

            <DialogActions>
                <LoadingButton
                    type='submit'
                    variant='contained'
                    onClick={handleSave}
                    loading={isLoading}>
                    Mentés
                </LoadingButton>
                <Button
                    type='button'
                    color='inherit'
                    variant='outlined'
                    onClick={onClose}>
                    Mégse
                </Button>
            </DialogActions>
        </Dialog>
    );
}
