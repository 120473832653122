import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { CommentStatus, CommentType } from 'models/Comment';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import ApiClient from 'utils/ApiClient';
import { ProjectAdminEditorContext } from './ProjectAdminEditorProvider';

type ProjectCommentProps = {
    open: boolean;
    onClose: VoidFunction;
};

export default function ProjectComment({ open, onClose }: ProjectCommentProps): JSX.Element {
    const [comment, setComment] = useState<string>('');
    const { enqueueSnackbar } = useSnackbar();

    const {
        project: [project],
        comments: [comments, setComments],
    } = useContext(ProjectAdminEditorContext);

    const onSubmit = async () => {
        if (!project?.id || !setComments) return;

        const payload = {
            status: CommentStatus.OPEN,
            type: CommentType.PROJECT,
            content: comment,
        };

        ApiClient.postCommentForProject(project?.id, payload)
            .then((comment) => {
                setComments([...comments, comment]);
                enqueueSnackbar('Komment elmentve', { variant: 'success' });
                onClose();
            })
            .catch((err) => {
                console.log(err);
                enqueueSnackbar('Ismeretlen hiba!', { variant: 'error' });
            });
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <DialogTitle>{'Komment hozzáadása'}</DialogTitle>
            <>
                <DialogContent>
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <TextField
                                fullWidth
                                label="Komment szövege"
                                value={comment}
                                placeholder="..."
                                onInput={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <Divider />

                <DialogActions>
                    <LoadingButton type="submit" variant="contained" onClick={onSubmit}>
                        {'Mentés'}
                    </LoadingButton>
                    <Button type="button" color="inherit" variant="outlined" onClick={onClose}>
                        Mégse
                    </Button>
                </DialogActions>
            </>
        </Dialog>
    );
}
