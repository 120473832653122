/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
// material
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';


type ChangeTenderBudgetProps = {
  open: boolean;
  onClose: VoidFunction;
  tenderBudget: number;
  updateTenderBudget: (newBudget: number) => Promise<void>;
};

export default function ChangeTenderBudget({
    open,
    onClose,
    tenderBudget,
    updateTenderBudget
}: ChangeTenderBudgetProps): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();
    const ProgramSchema = Yup.object().shape({
        budget: Yup.number().positive().required('Új összeg megadása szükséges!'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            budget: tenderBudget,
        },
        validationSchema: ProgramSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            await updateTenderBudget(values.budget);
            setSubmitting(false);
        },
    });

    const { errors, values, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <DialogTitle>{'Keretösszeg módosítás'}</DialogTitle>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={3} direction="column">

                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="Új keretösszeg"
                                    {...getFieldProps('budget')}
                                    type='number'
                                    value={values.budget}
                                    error={Boolean(touched.budget && errors.budget)}
                                    helperText={touched.budget && errors.budget}
                                />
                            </Grid>

                        </Grid>
                    </DialogContent>

                    <Divider />

                    <DialogActions>
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                            {'Mentés'}
                        </LoadingButton>
                        <Button type="button" color="inherit" variant="outlined" onClick={onClose}>
                            Mégse
                        </Button>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    );
}
