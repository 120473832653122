import React from 'react';
/* import { noCase } from 'change-case'; */
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { Icon } from '@iconify/react';
import emailFill from '@iconify/icons-eva/email-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import {
    Box,
    List,
    Badge,
    Button,
    Avatar,
    Tooltip,
    Divider,
    ListItem,
    Typography,
    ListItemText,
    ListSubheader,
    ListItemAvatar,
} from '@material-ui/core';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import { useNotifications } from 'contexts/NotificationsContext';
import Notification, { NotificationType } from 'models/Notification';
import ApiClient from 'utils/ApiClient';
import { Paths } from 'routes';
import LocalStorageManager from 'utils/LocalStorageManager';

function renderContent(notification: Notification) {
    const title = (
        <Typography variant="subtitle2">
            {notification.type === NotificationType.SYSTEM ? 'Rendszer üzenet' : notification?.title}
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                &nbsp; {notification.content ?? 'Nincs tárgy'}
            </Typography>
        </Typography>
    );

    if (notification.type === NotificationType.SYSTEM) {
        return {
            avatar: <img alt={'Notification title'} src="/static/icons/ic_notification_mail.svg" />,
            title,
        };
    }
    if (notification.type === NotificationType.MESSAGE) {
        return {
            avatar: <img alt={'Notification title'} src="/static/icons/ic_notification_chat.svg" />,
            title,
        };
    }
    return {
        avatar: null,
        title,
    };
}

type NotificationItemProps = {
    notification: Notification;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function NotificationItem({ notification, setOpen }: NotificationItemProps) {
    const { avatar, title } = renderContent(notification);
    const navigate = useNavigate();

    return (
        <ListItem
            button
            disableGutters
            key={notification.id}
            sx={{
                'py': 1.5,
                'px': 2.5,
                '&:not(:last-of-type)': { mb: '1px' },
                ...(notification.opened && {
                    bgcolor: 'action.selected',
                }),
            }}
            onClick={() => {
                setOpen(false);
                navigate((Paths.MESSAGES + notification.id) as string, {
                    state: {
                        notification: notification,
                    },
                });
            }}
        >
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={title}
                secondary={
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.disabled',
                        }}
                    >
                        <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
                        {notification.created &&
                            typeof notification.created != 'string' &&
                            formatDistanceToNow(notification.created as Date)}
                    </Typography>
                }
            />
        </ListItem>
    );
}

export default function NotificationsPopover(): JSX.Element {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { notifications, setNotifications } = useNotifications();

    const navigate = useNavigate();

    const user = LocalStorageManager.getUser();

    const totalUnRead = notifications.filter((n) => user?.accountId != n.sender?.id && !n.opened).length;

    const handleMarkAllAsRead = async () => {
        const inbox = notifications.filter((n) => user?.accountId != n.sender?.id);
        const sent = notifications.filter((n) => user?.accountId === n.sender?.id);
        const openeds = inbox.filter((n) => n.opened);
        const notOpeneds = inbox.filter((n) => !n.opened);

        ApiClient.bulkOpenNotifications(notOpeneds)
            .then((updateds) => setNotifications([...openeds, ...updateds, ...sent]))
            .catch((err) => console.log(err));
    };

    return (
        <>
            <MIconButton ref={anchorRef} onClick={() => setOpen(true)} color={open ? 'primary' : 'default'}>
                <Badge badgeContent={totalUnRead} color="error">
                    <Icon icon={emailFill} width={20} height={20} />
                </Badge>
            </MIconButton>

            <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current} sx={{ width: 360 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">Értesítések</Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Önnek {totalUnRead} olvasatlan üzenete van
                        </Typography>
                    </Box>

                    {totalUnRead > 0 && (
                        <Tooltip title=" Mark all as read">
                            <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                                <Icon icon={doneAllFill} width={20} height={20} />
                            </MIconButton>
                        </Tooltip>
                    )}
                </Box>

                <Divider />

                <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
                    <List
                        disablePadding
                        subheader={
                            <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                                Új
                            </ListSubheader>
                        }
                    >
                        {notifications
                            .filter((n) => n.sender?.id != user?.accountId)
                            .sort((a, b) => (b.created as Date).getTime() - (a.created as Date).getTime())
                            .slice(0, 4)
                            .map((notification) => (
                                <NotificationItem key={notification.id} notification={notification} setOpen={setOpen} />
                            ))}
                    </List>
                </Scrollbar>

                <Divider />

                <Box sx={{ p: 1 }}>
                    <Button
                        fullWidth
                        disableRipple
                        onClick={() => {
                            setOpen(false);
                            navigate(Paths.MESSAGES);
                        }}
                    >
                        Üzenetek megnyitása
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}
