/* eslint-disable no-unused-vars */
import FieldValue from './FieldValue';
import Project from './Project';
import User from './User';

export enum CommentVisibility {
    PRIVATE = 'private',
    PLATFORM = 'platform',
    PUBLIC = 'public',
}

export enum CommentStatus {
    OPEN = 'open',
    CLOSED = 'closed',
}

export enum CommentType {
    // INVITATION_CORRECTION = 'invitation_correction',
    // CONTRACT_CORRECTION = 'contract_correction',
    // SETTLEMENT_CORRECTION = 'settlement_correction',
    PROJECT = 'project',
    PRIVATE = 'private',
    PLATFORM = 'platform',
    CORRECTION_SUGGESTION = 'correction_suggestion',
    CORRECTION = 'correction',
}

export default class Comment {
    constructor(
        public id?: string,
        public created?: Date,
        public modified?: Date,
        public visibility?: CommentVisibility,
        public status?: CommentStatus,
        public type?: CommentType,
        public content?: string,
        public user?: User,
        public fieldValue?: FieldValue,
        public project?: Project,
    ) {}
}
