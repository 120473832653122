
function path(root: string, sublink: string) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/register'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
    page404: '/404',
    page500: '/500',
};

export const PATH_APP = {
    dashboard: 'dashboard',
    general: {
        app: '/app',
        uploadFile: '/pdf-required',
        admin: '/admin',
    },
    tender: {
        tender: '/:id',
        list: '/tenderboard',
        edit: '/tenders/:tenderId',
        application: '/:id/application',
    },
    project: {
        root: '/projects',
        list: '/projectboard',
        application: '/:id/application',
        creation: '/projects/:projectId',
    },
    mail: {
        root: '/mail',
        all: '/mail/all',
    },
    chat: {
        root: '/chat',
        new: '/chat/new',
        conversation: '/chat/:conversationKey',
    },
    admin: {
        tenders: '/admin/tenders',
        dashboard: '/admin/dashboard'
    },
    user: {
        root: '/user',
        tenders: '/user/tenders',
        tender: '/user/tenders/:id',
        profile: '/user/profile',
        programs: '/user/programs',
        list: '/userboard',
        newUser: '/user/new',
        account: '/user/account',
        status: '/status',
        publicAccount: '/account',
        activate: '/activate',
    },
};

export const PATH_DOCS = 'https://minimal-docs.vercel.app/';
