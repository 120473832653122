import Account from './Account';

/* eslint-disable no-unused-vars */
export enum NotificationType {
    SYSTEM = 'system',
    MESSAGE = 'message',
}

export default class Notification {
    constructor(
        public id?: string,
        public created?: Date | string,
        public modified?: Date | string,
        public type?: NotificationType,
        public title?: string,
        public content?: string,
        public parentId?: string,
        public messageCount?: number,
        public opened?: Date | string,
        public sent?: Date | string,
        public sender?: Account,
        public recipient?: Account,
        public emailId?: string,
        public attachments?: string[],
    ) {}
}
