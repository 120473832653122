/* eslint-disable max-len */
import { Box, BoxProps } from '@material-ui/core';
import React from 'react';

export default function Logo({ sx }: BoxProps) {
    // const theme = useTheme();
    // const PRIMARY_LIGHT = theme.palette.primary.light;
    // const PRIMARY_MAIN = theme.palette.primary.main;
    // const PRIMARY_DARK = theme.palette.primary.dark;

    return (
        <Box sx={{ width: 40, height: 40, ...sx }}>
            {/* TODO: MTPA LOGO */}
            {/* <img src="/static/icons/MTPA-logo-color-loading.svg" alt="MTPA logo"/> */}
        </Box>
    );
}
