import { UserRole } from 'enums/UserRole';
import React, { HTMLProps, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Paths } from 'routes';
import ApiClient from 'utils/ApiClient';
import LocalStorageManager from 'utils/LocalStorageManager';

type AuthGuardProps = {
    permissions: UserRole[],
} & HTMLProps<HTMLDivElement>

export default function AuthGuard(props: AuthGuardProps): JSX.Element {
    const [isLoading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        // Only load page content once identity is confirmed
        setLoading(true);

        ApiClient.getCurrentUser()
            .then((user) => {
                // If user has no permission, navigate to 404
                if (!user?.role || !props.permissions.includes(user.role)) {
                    navigate(Paths.PAGE_NOT_FOUND);

                // If user has permission, save user to LS, and load page content
                } else {
                    LocalStorageManager.setUser(user);
                    setLoading(false);
                }
            });
    },
    [navigate, props.permissions]);
    return <>{!isLoading && props.children}</>;
}
