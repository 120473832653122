import ProjectState, { ProjectStateOptions } from 'enums/ProjectState';
import TenderState, { TenderStateOptions } from 'enums/TenderState';
import { Option } from 'models/Option';
import { useMemo } from 'react';
import ProjectStateService from 'utils/ProjectStateService';
import TenderStateService from 'utils/TenderStateService';

export const useTenderTransition = (current: TenderState = TenderState.DRAFT)
: [Option<TenderState>, Option<TenderState>[]] => {
    const currentOption = useMemo(() => TenderStateOptions.find((s) => s.value === current) ?? TenderStateOptions[0],
        [current]);

    const options = useMemo(() => {
        const arr = TenderStateOptions
            .filter((s) => TenderStateService.isTransitionAllowed(current, s.value));
        return currentOption ? arr.concat(currentOption) : arr;
    },
    [current, currentOption]);

    return [currentOption, options];
};

export const useProjectTransition = (current: ProjectState = ProjectState.INVITATION_DRAFT)
: [Option<ProjectState>, Option<ProjectState>[]] => {
    const currentOption = useMemo(() => ProjectStateOptions.find((s) => s.value === current) ?? ProjectStateOptions[0],
        [current]);

    const options = useMemo(() => {
        const arr = ProjectStateOptions
            .filter((s) => ProjectStateService.isTransitionAllowed(current, s.value));
        return currentOption ? arr.concat(currentOption) : arr;
    },
    [current, currentOption]);

    return [currentOption, options];
};
