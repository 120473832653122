import { SettingsContext, SettingsProvider } from 'contexts/SettingsContext';
import Attachment from 'models/Attachment';
import Comment from 'models/Comment';
import Project from 'models/Project';
import ProjectMember from 'models/ProjectMember';
import Tender from 'models/Tender';
import User from 'models/User';
import { useSnackbar } from 'notistack';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'routes';
import ApiClient from 'utils/ApiClient';

type ProjectAdminEditorContextType = {
    project: [project?: Project, setProject?: (p: Project) => void],
    comments: [comments: Comment[], setComments?: (c: Comment[]) => void],
    attachments: [attachments: Attachment[], setAttachments?: (a: Attachment[]) => void],
    tender?: Tender,
    judges: [judges: User[], setJudges?: (judges: User[]) => void],
    projectJudges: [projectJudges: ProjectMember[], setProjectJudges?: (judges: ProjectMember[]) => void]
};

type ProjectAdminEditorProviderProps = {
    children: ReactNode,
    projectId: string,
};

export const ProjectAdminEditorContext = createContext<ProjectAdminEditorContextType>({
    project: [],
    comments: [[]],
    attachments: [[]],
    judges: [[]],
    projectJudges: [[]]
});

export default function ProjectAdminEditorProvider({
    children,
    projectId,
}: ProjectAdminEditorProviderProps): JSX.Element {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [project, setProject] = useState<Project>();
    const [tender, setTender] = useState<Tender>();
    const [comments, setComments] = useState<Comment[]>([]);
    const [attachments, setAttachments] = useState<Attachment[]>([]);
    const [judges, setJudges] = useState<User[]>([]);
    const [projectJudges, setProjectJudges] = useState<ProjectMember[]>([]);

    useEffect(() => {
        ApiClient.getProject(projectId)
            .then((res) => {
                setProject(res);
                return ApiClient.getTender(res?.tenderId);
            })
            .then((res) => setTender(res))
            .catch((err) => {
                console.error(err);
                navigate(Paths.PAGE_NOT_FOUND);
            });

        let localJudges:User[] = [];
        ApiClient.listJudges()
            .then((res) => {
                setJudges(res);
                localJudges = res;
                return ApiClient.listJudgesByProjectId(projectId);
            })
            .then((res) => {
                const mapped:User[] = [];
                for (const member of res) {
                    const judge = localJudges.find((j) => j.id === member.userId);
                    if (judge) {
                        mapped.push(judge);
                    }
                }
                setProjectJudges(mapped);
            });


        ApiClient.listProjectComments(projectId)
            .then((res) => setComments(res))
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Hozzászólások lekérése sikertelen!', { variant: 'error' });
            });

        ApiClient.listAttachments({ projectId })
            .then((attachments) => setAttachments(attachments))
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Csatolmányok lekérése sikertelen!', { variant: 'error' });
            });
    },
    [enqueueSnackbar, navigate, projectId]);

    return (
        <ProjectAdminEditorContext.Provider value={{
            project: [project, setProject],
            attachments: [attachments, setAttachments],
            comments: [comments, setComments],
            judges: [judges, setJudges],
            projectJudges: [projectJudges, setProjectJudges],
            tender,
        }}>
            {children}
        </ProjectAdminEditorContext.Provider>
    );
}

export { SettingsProvider, SettingsContext };
