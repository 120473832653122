import Notification from 'models/Notification';
import User from 'models/User';
import React, { useEffect, useState } from 'react';
import ApiClient from 'utils/ApiClient';
import LocalStorageManager from 'utils/LocalStorageManager';

type NotificationsContextType = {
    notifications: Notification[];
    setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
    filter: NotificationFilter;
    setFilter: React.Dispatch<React.SetStateAction<NotificationFilter>>;
    threads: Thread[];
};

export type NotificationFilter = 'inbox' | 'sent';

export type Thread = {
    parent?: Notification;
    children?: Notification[];
};

function createCtx<ContextType>() {
    const ctx = React.createContext<ContextType | undefined>(undefined);
    function useCtx(): ContextType {
        const c = React.useContext(ctx);
        if (!c) throw new Error('useNotifications must be inside NotificationsProvider');
        return c;
    }
    return [useCtx, ctx.Provider] as const;
}

const [useNotifications, CtxProvider] = createCtx<NotificationsContextType>();

const NotificationsProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [threads, setThreads] = useState<Thread[]>([]);
    const [filter, setFilter] = useState<NotificationFilter>('inbox');

    useEffect(() => {
        const user = LocalStorageManager.getUser();

        if (user && user.accountId) {
            ApiClient.listNotifications((user as User).accountId as string)
                .then((notifications) => {
                    notifications.forEach((n) => {
                        if (typeof n.created === 'string') {
                            n.created = new Date(n.created);
                        }
                    });
                    setNotifications(notifications);
                    setThreads(createThreads(notifications));
                })
                .catch((err) => console.log(err));
        }
    }, []);

    useEffect(() => {
        notifications.forEach((n) => {
            if (typeof n.created === 'string') {
                n.created = new Date(n.created);
            }
        });
        setThreads(createThreads(notifications));
    }, [notifications]);

    const createThreads = (notifications: Notification[]): Thread[] => {
        const parents = notifications.filter((n) => !n.parentId);
        const children = notifications.filter((n) => n.parentId);

        const threads = parents.map((p): Thread => {
            return {
                parent: p,
                children: [],
            };
        });

        children.forEach((child) => {
            threads.find((thread) => thread?.parent?.id === child.parentId)?.children?.push(child);
        });

        return threads;
    };

    return (
        <CtxProvider
            value={{
                notifications,
                setNotifications,
                filter,
                setFilter,
                threads,
            }}
        >
            {children}
        </CtxProvider>
    );
};

export { useNotifications, NotificationsProvider };
