/* eslint-disable no-unused-vars */
export enum UserRole {
    ADMIN = 'admin',
    PRESIDENT = 'president',
    MEMBER = 'member',
    JUDGE = 'judge',
    USER = 'user',
}

export function isAdmin(role: UserRole): boolean {
    return UserRole.ADMIN === role;
}

export function isUser(role: UserRole): boolean {
    return UserRole.USER === role;
}
