import messageFill from '@iconify/icons-eva/message-circle-fill';
import { Icon } from '@iconify/react';
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControl,
    Grid, Stack, TextField, Typography
} from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import AccountType from 'enums/AccountType';
import ProjectState, { ProjectStateNames } from 'enums/ProjectState';
import { UserRole } from 'enums/UserRole';
import Account from 'models/Account';
import { CommentType } from 'models/Comment';
import User from 'models/User';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ApiClient from 'utils/ApiClient';
import LocalStorageManager from 'utils/LocalStorageManager';
import typography from '../../../../theme/typography';
import { ProjectAdminEditorContext } from './ProjectAdminEditorProvider';
import ProjectAdminSideMenu from './ProjectAdminSideMenu';
import ProjectComment from './ProjectComment';

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...typography.subtitle2,
    color: theme.palette.text.secondary,
}));


export default function ProjectAdminEditor(): JSX.Element {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [account, setAccount] = useState<Account>();

    const user = useMemo(() => LocalStorageManager.getUser(), []);

    const {
        project: [project],
        // eslint-disable-next-line no-unused-vars
        judges: [judges, setJudges],
        projectJudges: [projectJudges, setProjectJudges],
        comments: [comments],
    } = useContext(ProjectAdminEditorContext);


    useEffect(() => {
        if (!user || !user?.accountId) return;

        ApiClient.getAccount(user.accountId)
            .then((res) => setAccount(res))
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Profil lekérése sikertelen!', { variant: 'error' });
            });
    },
    [enqueueSnackbar, user]);

    const handleJudgeChange = (e:any, newValue:User[]) => {
        console.log(newValue);
        if (setProjectJudges) {
            setProjectJudges(newValue);
        }
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <Card sx={{ p: 2, mb: 3 }}>
                        <CardHeader title="Általános adatok" />
                        <CardContent>
                            <Stack spacing={2} sx={{ p: 1 }}>
                                <Stack direction="row">
                                    <LabelStyle sx={{ mr: 1 }}>Projekt címe:</LabelStyle>
                                    <Typography variant="body2">{project?.name ?? 'Névtelen projekt'}</Typography>
                                </Stack>

                                <Stack direction="row">
                                    <LabelStyle sx={{ mr: 1 }}>Pályázó neve:</LabelStyle>
                                    <Typography variant="body2">
                                        {`${account?.lastName ?? ''} ${account?.firstName ?? ''}`}
                                    </Typography>
                                </Stack>

                                { account?.type === AccountType.LEGAL &&
                                    <Stack direction="row">
                                        <LabelStyle sx={{ mr: 1 }}>Pályázó cége</LabelStyle>
                                        <Typography variant="body2">{account.organizationName ?? ''}</Typography>
                                    </Stack>
                                }

                                <Stack direction="row">
                                    <LabelStyle sx={{ mr: 1 }}>Projekt státusza</LabelStyle>
                                    <Typography variant="body2">
                                        {ProjectStateNames[project?.state ?? ProjectState.INVITATION_DRAFT]}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>

                    {User.hasRole(user, [UserRole.ADMIN]) &&
                    <>
                        <Card sx={{ p: 2, mb: 3 }}>
                            <CardHeader title="Bírálók kiválasztása" />
                            <CardContent>
                                <Stack spacing={3}>


                                    <FormControl>

                                        <Autocomplete
                                            multiple
                                            id="checkboxes-tags-demo"
                                            options={judges || []}
                                            isOptionEqualToValue={(a, b) => a.id === b.id}
                                            disableCloseOnSelect
                                            onChange={handleJudgeChange}
                                            value={projectJudges}
                                            getOptionLabel={(option) => `${option.lastName} ${option.firstName}`}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {`${option.lastName} ${option.firstName}`}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                        />

                                    </FormControl>


                                </Stack>

                            </CardContent>
                        </Card>

                        <Card sx={{ p: 2 }}>
                            <CardHeader title="Adminisztrátori megjegyzések" />
                            <CardContent>
                                <Stack spacing={2} sx={{ p: 1, mb: 2 }}>
                                    {comments.filter((c) => c.type === CommentType.PROJECT).map((comment) => (
                                        <Stack key={comment.id}>
                                            <LabelStyle>
                                                {'' + comment?.user?.firstName + ' ' + comment?.user?.lastName}
                                            </LabelStyle>
                                            <LabelStyle sx={{ mb: 1 }}>
                                                {moment(comment?.created).format('YYYY.MM.DD - hh:mm')}
                                            </LabelStyle>
                                            <Typography variant="body2">{comment?.content}</Typography>
                                        </Stack>
                                    ))}
                                </Stack>

                                <Stack direction="row" sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        size="small"
                                        onClick={() => setOpen(true)}
                                        startIcon={<Icon icon={messageFill} />}>
                                        Komment hozzáadása
                                    </Button>
                                </Stack>
                            </CardContent>
                        </Card>
                    </>}
                </Grid>

                <ProjectAdminSideMenu />
            </Grid>
            {project && <ProjectComment open={open} onClose={() => setOpen(false)}/>
            }
        </>
    );
}
